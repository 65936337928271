import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container1">
      <Helmet>
        <title>GimmeCard ТЧК</title>
        <meta property="og:title" content="GimmeCard ТЧК" />
      </Helmet>
      <div className="home-container2">
        <span className="home-text10">
          <span className="home-text11">
            Оформление международной банковской карты
          </span>
          <br></br>
          <br></br>
          <span>
            {' '}
            Вы переходите в консьерж-сервис GimmeCard, где сможете со скидкой
            для клиентов Точки заказать карту VISA иностранного банка.
          </span>
          <br className="home-text15"></br>
          <span>
            Для Вашего удобства мы сделали телеграм канал с самой необходимой
            информацией.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a
            href="https://t.me/+p9a0uEKaPPcxNWYy"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link10"
          >
            Подпишитесь!
          </a>
          <br className="home-text17"></br>
          <br className="home-text18"></br>
          <span>БАНКОВСКИЕ КАРТЫ, КОТОРЫЕ РАБОТАЮТ ЗА ГРАНИЦЕЙ</span>
          <br className="home-text20"></br>
          <br className="home-text21"></br>
          <a
            href="https://t.me/c/2461600736/4"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link11"
          >
            Кратко главное о картах, работающих за границей
          </a>
          <br className="home-text22"></br>
          <a
            href="https://t.me/c/2461600736/5"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link12"
          >
            Как открыть карту и что для этого нужно?
          </a>
          <br className="home-text23"></br>
          <a
            href="https://t.me/c/2461600736/6"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link13"
          >
            Сколько стоит открытие и владение картой?
          </a>
          <br className="home-text24"></br>
          <a
            href="https://t.me/c/2461600736/8"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link14"
          >
            График получения карты
          </a>
          <br className="home-text25"></br>
          <a
            href="https://t.me/c/2461600736/9"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link15"
          >
            Если карта нужна срочно …
          </a>
          <br className="home-text26"></br>
          <br></br>
          <span>КАКИЕ КАРТЫ ДОСТУПНЫ?</span>
          <br className="home-text29"></br>
          <br className="home-text30"></br>
          <a
            href="https://t.me/c/2461600736/10"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link16"
          >
            VISA/Mastercard Gold
          </a>
          <br className="home-text31"></br>
          <a
            href="https://t.me/c/2461600736/11"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link17"
          >
            VISA Platinum
          </a>
          <br className="home-text32"></br>
          <a
            href="https://t.me/c/2461600736/12"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link18"
          >
            UnionPay Standard/Platinum
          </a>
          <br className="home-text33"></br>
          <a
            href="https://t.me/c/2461600736/13"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link19"
          >
            VIP карты VISA Infinite
          </a>
          <br className="home-text34"></br>
          <br></br>
          <span>ПОПОЛНЕНИЕ И ВОЗВРАТ. ПЕРЕВОДЫ.</span>
          <br className="home-text37"></br>
          <br className="home-text38"></br>
          <a
            href="https://t.me/c/2461600736/14"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link20"
          >
            Пополнение карт из российских банков
          </a>
          <br className="home-text39"></br>
          <a
            href="https://t.me/c/2461600736/15"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link21"
          >
            Возврат денег в российские банки
          </a>
          <br className="home-text40"></br>
          <a
            href="https://t.me/c/2461600736/16"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link22"
          >
            Международные переводы
          </a>
          <br className="home-text41"></br>
          <br></br>
          <span>ЖИЗНЕННЫЕ СИТУАЦИИ (какая карта подойдёт)</span>
          <br className="home-text44"></br>
          <br className="home-text45"></br>
          <a
            href="https://t.me/c/2461600736/19"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link23"
          >
            Карта для аренды автомобиля
          </a>
          <br className="home-text46"></br>
          <a
            href="https://t.me/c/2461600736/20"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link24"
          >
            Карта для получения зарплаты, контрактных выплат
          </a>
          <br className="home-text47"></br>
          <a
            href="https://t.me/c/2461600736/18"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link25"
          >
            Карта для несовершеннолетнего
          </a>
          <br className="home-text48"></br>
          <a
            href="https://t.me/c/2461600736/17"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link26"
          >
            Карта для онлайн покупок
          </a>
          <br className="home-text49"></br>
          <br className="home-text50"></br>
          <span>Для подачи заявки на карту нужно заполнить анкету в боте</span>
          <br className="home-text52"></br>
          <br></br>
          <span>Перейти в бот можно по </span>
          <a
            href="https://t.me/gimmecard_bot?start=link_KtINnQo8q4"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link27"
          >
            ссылке
          </a>
          <span> или отсканировав QR-код.</span>
          <br></br>
        </span>
        <a
          href="https://t.me/gimmecard_bot?start=link_KtINnQo8q4"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link28"
        >
          <img alt="image" src="/qr-code-200h.png" className="home-image" />
        </a>
      </div>
    </div>
  )
}

export default Home
